import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./header.css";
import { imagebasePath } from "../../constant";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState } from "react";
import Menu from "../../assets/images/menu-bar.png";
import Close from "../../assets/images/close.png";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleChange = () => {
    setMenuOpen(false);
  };
  return (
    <header>
      {/* menu-icon */}
      <div className="app-bar">
        <LinkContainer to="/">
          <img
            src={`${imagebasePath}/jsk_logo.svg`}
            className="logo"
            alt="jsk-logo"
          />
        </LinkContainer>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <img src={Menu} className="icon" alt="Menu" />
        </div>
        <ul className={menuOpen ? "nav-show" : "nav-hide"}>
          
          <div onClick={() => setMenuOpen(false)} className="close-icon">
            <img src={Close} className="icon" alt="close" />
          </div>
          <div onClick={handleChange}>
            <LinkContainer to="/" >
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/partner" >
              <Nav.Link>Partner</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about" >
              <Nav.Link>About</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/courses" >
              <Nav.Link>Courses</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/job-board" >
              <Nav.Link>Remote Jobs</Nav.Link>
            </LinkContainer>
            <LinkContainer to="remote-companies" >
              <Nav.Link>Remote Companies </Nav.Link>
            </LinkContainer>
          </div>
        </ul>
      </div>
      {/* web-menu */}
      <div className="container">
        <div className="row align-items-center web-menu">
          <div className="col-2">
            <LinkContainer to="/">
              <img
                src={`${imagebasePath}/jsk_logo.svg`}
                className="logo"
                alt="jsk-logo"
              />
            </LinkContainer>
          </div>
          <div className="col-8 d-flex justify-content-center">
            <Navbar>
              <Nav className="mr-auto nav-item main-menu">
                <LinkContainer to="/">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/partner">
                  <Nav.Link>Partner</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about">
                  <Nav.Link>About</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/courses">
                  <Nav.Link>Courses</Nav.Link>
                </LinkContainer>
                <NavDropdown title="Jobs" id="navbarScrollingDropdown">
                  <LinkContainer to="/job-board">
                    <Nav.Link>Remote Jobs</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="remote-companies">
                    <Nav.Link>Remote Companies </Nav.Link>
                  </LinkContainer>
                </NavDropdown>

                {/* <LinkContainer to="/join-us">
                                    <Nav.Link>Join us</Nav.Link>
                                </LinkContainer> */}
              </Nav>
            </Navbar>
          </div>
          <div className="col-2">
            <button
              className="btn custom-btn"
              onClick={() =>
                window.open("https://topmate.io/javascript_kingdom")
              }
            >
              {" "}
              Book A Demo{" "}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
