import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Loader from "./Components/Loader";
import NotFound from "./Components/NotFound";
const Main = lazy(() => import("./container/Main"));
const Partner = lazy(() => import("./container/Partner"));
const About = lazy(() => import("./container/About"));
const Joinus = lazy(() => import("./container/Joinus"));
const Classroom = lazy(() => import("./container/classroom"));
const RegistrationForm = lazy(() =>
  import("./container/classroom/RegistrationForm")
);
const CourseDetail=lazy(()=>import("./container/CourseDetail"))
const JobBoard = lazy(() => import("./container/JobBoard"));
const RemoteJob = lazy(() => import("./container/RemoteJobs"));

function App() {
  return (
    <div className="layout-wrapper">
      <Router>
        <Header/>
        <div className="layout-page-content">
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route exact path="/" element={<Main />} />
              <Route path="/partner" element={<Partner />} />
              <Route path="/about" element={<About />} />
              <Route path="/courses" element={<Classroom />} />
              <Route path="/join-us" element={<Joinus />} />
              <Route
                path="/courses/registration"
                element={<RegistrationForm />}
              />
              <Route path="/course/:coursePath" element={<CourseDetail />} />
              <Route path="/job-board" element={<JobBoard />} />
              <Route path="/remote-jobs" element={<RemoteJob />} />
              <Route path="/remote-companies" element={<RemoteJob />} />
              {/* for Not found */}
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </div>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
