import styled from "styled-components";

export const Img = styled.div`
  width: 130px;
  height: 50px;
`;
export const Box = styled.div`
  padding: 50px 0px 0px;

  background: #f0f5ff;
  width: 100%;
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1140px;
  margin: 0 auto;
  /* background: red; */
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
export const Decription = styled.p`
  color: #5c6d8a;
  margin-bottom: 20px;
  font-size: 14px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 50px;

  // @media (max-width: 1000px) {
  //   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // }
`;

export const FooterLink = styled.a`
  color: #5c6d8a;
  margin-bottom: 20px;
  font-size: 16px;
  text-decoration: none;
  font-family: "DMSans-Regular";

  &:hover {
    color: #5c6d8a;
    transition: 200ms ease-in;
  }
`;
export const SocialLink = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
`;
export const Icon = styled.div`
  width: 32px;
  height: 32px;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;

  &:hover {
    color: #5c6d8a;
    transition: 200ms ease-in;
  }
`;
export const Heading = styled.p`
  font-size: 17px;
  color: #001840;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: "DM Sans";
`;
