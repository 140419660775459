import React from "react";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
  Icon,
  Decription,
  SocialLink,
} from "./FooterStyles";
import "./footer.css";

const Footer = () => {
  return (
    <Box>
      <Container>
        <Row className="mb-5">
          <Column>
            <Heading>JavaScript Kingdom</Heading>
            <Decription>
              JavaScript Kingdom is your haven in the realm of coding, welcoming
              JavaScript developers of all stripes. Whether you're taking your
              first steps or have a tapestry of experience, our platform is
              crafted to elevate your JavaScript prowess.
            </Decription>
          </Column>
          <Column>
            <Heading>Services</Heading>
            <FooterLink href="/about">About</FooterLink>
            <FooterLink href="/partner">Partner</FooterLink>
            <FooterLink href="/jsk-classroom">JSK Classroom</FooterLink>
          </Column>
          <Column>
            <Heading>Resources</Heading>
            <FooterLink href="#">FAQs</FooterLink>
            <FooterLink href="#">Call us</FooterLink>
          </Column>
          <Column>
            <Heading>Social Media</Heading>
            <SocialLink>
              <FooterLink
                href="https://www.facebook.com/javascriptkingdom/"
                target="_blank"
              >
                <Icon>
                  <i className="fab fa-facebook-f"></i>
                </Icon>
              </FooterLink>
              <FooterLink
                href="https://www.instagram.com/javascript.kingdom/"
                target="_blank"
              >
                <Icon>
                  <i className="fab fa-instagram"></i>
                </Icon>
              </FooterLink>
              <FooterLink
                href="https://twitter.com/javascriptking"
                target="_blank"
              >
                <Icon>
                  <i className="fab fa-twitter"></i>
                </Icon>
              </FooterLink>
              <FooterLink
                href="https://www.youtube.com/@javascriptkingdom3516"
                target="_blank"
              >
                <Icon>
                  <i className="fab fa-youtube"></i>
                </Icon>
              </FooterLink>
              <FooterLink
                href="https://discord.com/invite/4D7JJxA"
                target="_blank"
              >
                <Icon>
                  <i className="fab fa-discord"></i>
                </Icon>
              </FooterLink>
              <FooterLink
                href="https://www.t.me/javascriptkingdom"
                target="_blank"
              >
                <Icon>
                  <i className="fab fa-telegram"></i>
                </Icon>
              </FooterLink>
              <FooterLink
                href="https://www.linkedin.com/company/javascript-kingdom/"
                target="_blank"
              >
                <Icon>
                  <i className="fab fa-linkedin"></i>
                </Icon>
              </FooterLink>
            </SocialLink>
          </Column>
        </Row>
      </Container>
      <div className="footer-copyright text-center py-3">
        © {new Date().getFullYear()} Copyright:
        <a href="https://javascriptkingdom.com/" target="_blank"> JavaScript Kingdom</a>
      </div>
    </Box>
  );
};
export default Footer;
